body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4;
  border-bottom: 2px solid #000;
}

.header p {
  margin: 0;
  font-size: 2em;
  color: #333;
}


.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.header {
  background-color: #282c34;
  padding: 1rem;
  text-align: center;
}

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
}

.nav-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #45a049;
}

.nav-button.active {
  background-color: #2e7d32;
}

.App {
  text-align: center;
  padding: 2rem;
}

.logout-button {
  padding: 0.7rem 1.5rem;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #b71c1c;
}

.meshin-button {
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.meshin-button:hover {
  background-color: #45a049;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

label {
  flex: 1;
  margin-right: 0.5rem;
  font-weight: bold;
}

.form-input {
  flex: 2;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-button {
  flex: 1;
  padding: 0.5rem;
  margin-left: 0.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:disabled {
  background-color: #b0c4de;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.65;
}

.form-button:hover {
  background-color: #0056b3;
}

.error-message {
  margin-top: 1rem;
  color: red;
  font-weight: bold;
  text-align: center;
}

.success-message {
  margin-top: 1rem;
  color: green;
  font-weight: bold;
  text-align: center;
}

.container {
  width: 100%;
  max-width: 90%;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.host-container {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
}

.host-title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.deployment-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eee;
  color: #555;
}

.deployment-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.deployment-info:last-child {
  border-bottom: none;
}

.status-alive {
  color: #28a745;
  font-weight: bold;
}

.status-dead {
  color: #dc3545;
  font-weight: bold;
}

.section-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.certificates-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 1rem;
}

.certificates-table thead {
  background-color: #007bff;
  color: white;
}

.certificates-table th,
.certificates-table td {
  padding: 0.75rem 1rem;
  text-align: left;
  border: 1px solid #ddd;
}

.certificates-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.certificates-table tbody tr:nth-child(even) {
  background-color: #fff;
}

.certificates-table tbody tr:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {

  .certificates-table th,
  .certificates-table td {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}

.nav-button.disabled {
  color: #ccc;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  cursor: not-allowed;
  pointer-events: none;
}

.nav-button {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  text-decoration: none;
  color: white;
  background-color: #4caf50;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-button.active {
  background-color: #388e3c;
}

.nav-button:hover:not(.disabled) {
  background-color: #45a049;
}

.builds-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.builds-table thead {
  background-color: #343a40;
  color: white;
}

.builds-table th,
.builds-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.builds-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.builds-table tbody tr:hover {
  background-color: #f1f1f1;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  display: inline-block;
}

.status-badge.success {
  background-color: #28a745;
  color: white;
}

.status-badge.failed {
  background-color: #dc3545;
  color: white;
}

.status-badge.building {
  background-color: #ffc107;
  color: white;
}


.retry-btn {
  background: linear-gradient(135deg, #5fffb7, #7ba4fe);
  color: white;
  border: none;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.retry-btn:hover {
  background: linear-gradient(135deg, #5fffb7, #7ba4fe);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.retry-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fc665e;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-btn:disabled {
  background-color: #d6d6d6;
  color: #aaa;
  cursor: not-allowed;
}

.pagination-btn:hover:not(:disabled) {
  background-color: #0056b3;
}

.page-info {
  font-size: 14px;
  font-weight: bold;
}

.configuration-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.configuration-container .title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.configuration-container .form-group {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.configuration-container .form-label {
  width: 200px;
  font-weight: bold;
  text-align: left;
}

.configuration-container .form-control {
  flex-grow: 4;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.configuration-container .form-checkbox-container {
  flex-grow: 5;
  display: flex;
  align-items: left;
}

.configuration-container .form-checkbox {
  margin: 0;
}

.configuration-container .btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.configuration-container .fetch-btn {
  background-color: #007bff;
  color: white;
}

.configuration-container .fetch-btn:hover {
  background-color: #0056b3;
}

.configuration-container .submit-btn {
  background-color: #28a745;
  color: white;
}

.configuration-container .submit-btn:hover {
  background-color: #218838;
}

.configuration-container .config-table-container {
  margin-top: 20px;
}

.configuration-container .config-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.configuration-container .config-table th,
.configuration-container .config-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.configuration-container .config-table th {
  background-color: #343a40;
  color: white;
}

.configuration-container .config-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.configuration-container .config-table tr:hover {
  background-color: #f1f1f1;
}

.configuration-container .error-message {
  color: #dc3545;
  font-weight: bold;
  margin-top: 15px;
}

.response-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card h2 {
  margin-bottom: 10px;
  color: #007BFF;
}

.card p {
  margin: 5px 0;
  color: #555;
}

.card strong {
  color: #333;
}

.sub-card {
  margin-top: 15px;
  padding: 10px;
  background: #f0f8ff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sub-card h3 {
  color: #0056b3;
}

.sub-card p {
  margin: 3px 0;
}